<template>
  <v-menu v-model="show" :close-on-content-click="false">
    <template v-slot:activator="{on}">
      <v-btn small icon v-on="on" class="ml-4" :title="$t('widgets.tasks.addTask')">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card min-width="300">
      <v-toolbar color="primary" dense dark>
        <v-toolbar-title>{{ $t("widgets.tasks.addTask") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form
            v-model="valid"
            ref="addTaskForm"
            lazy-validation
            @submit.stop.prevent="add"
        >
          <v-text-field :label="$t('widgets.tasks.fields.title')" v-model="newTask.title"
                        autofocus
                        @paste.prevent="checkPaste"
                        :rules="ValidationRules.required"></v-text-field>
          <!--            <v-text-field label=""></v-text-field>-->
          <v-card-actions>
            <v-btn color="success" type="submit">{{ $t("actions.add") }}</v-btn>
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import MixinValidation from "@/Mixins/ValidationRules"

export default {
  name: "AddTask",
  mixins: [MixinValidation],
  props: {},
  data() {
    return {
      valid: false,
      newTask: {
        title: "",
        done: false
      },
      show: false
    }
  },
  methods: {
    add: function () {
      if (this.$refs.addTaskForm.validate()) {
        this.$emit('add', this.newTask)
        this.resetNewTask()
        this.show = false
      }
    },
    resetNewTask() {
      this.newTask = Object.assign({}, {
        title: "",
        done: false
      })
    },
    checkPaste: async function (e) {
      let pasted = e.clipboardData.getData("text")
      if (["\n","\r"].some(c=>pasted.includes(c))) {
        let tmp = pasted.split(/\r?\n/g)
        this.show = false
        for (let newTask of tmp) {

          if (newTask) {
            this.$emit("add", {title: newTask, done: false})
            await this.sleep(150)
          }
        }
      } else {
        this.newTask.title += pasted
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.resetNewTask()
      }
    }
  }
}
</script>

<style scoped>

</style>
